<template>
  <div class="w1092 pt60" v-if="model">
    <div class="board">
      <div class="boardrd first">
        <span v-if="model.status === 'ANSWER'" class="nicon wtblbg">{{$t('front.qnaStatus.answer')}}</span>
        <span v-if="model.status === 'WAIT'" class="nicon bkblbg">{{$t('front.qnaStatus.wait')}}</span>
        <span class="nicon ignbg ml10">{{$t('front.qnaTypeCategory.' + model.faqType)}}</span>
        <span class="ml10">{{model.title}}</span>
      </div>
      <div class="boardrd">
        <span class="medal medal1"><em class="level">1</em></span>
        <span>{{model.memName}}</span><span class="ml10">{{dateFormatForBoard(model.regDate)}}</span>
      </div>
      <div class="boardbox">
        <p>{{model.content}}</p>
        <p class="rdcon rdconbg mt20" v-if="replyContent">
          {{replyContent}}
        </p>
      </div>
    </div>

    <div class="boardbtn">
      <ui-button :text="$t('front.board.prev')" :className="'grsbtn left'" @click="onClickPrevNext('prev')"/>
      <ui-button :text="$t('front.board.list')" :className="'grbbtn'" @click="onClickList"/>
      <ui-button :text="$t('front.board.next')" :className="'grsbtn right'" @click="onClickPrevNext('next')"/>
    </div>

  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'

export default {
  name: 'QnaRead',
  components: {
    UiButton
  },
  data () {
    return {
      boardType: 'faq',
      boardIdx: null,
      model: null,
      replyContent: ''
    }
  },
  created () {
    this.boardIdx = this.$route.params.boardIdx
    this.loadBoardDetail(this.boardIdx)
  },
  methods: {
    async loadBoardDetail (boardIdx) {
      const params = {
        boardIdx: boardIdx,
        boardType: this.boardType
      }
      this.model = await this.getBoardDetail(params)
      this.replyContent = this.model.comment.length > 0 ? this.model.comment[0].content : ''
    },
    onClickPrevNext (type) {
    },
    onClickList () {
      this.$router.push({ name: 'qna' })
    }
  }
}
</script>
<style scoped>
.levelname {margin: 2px;border-radius: 16px;background: #e8f3fd;text-align: center;font-size: 12px;font-weight: bold;padding: 20px 0;}
.levelname .medal {width: 39px;height: 46px;background-size: 35px;}
.levelname .name {font-size: 16px;}
.levelname .medal .level {font-size: 16px;top: 5px;left: 0;width: 34px;}
.medal1 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal2 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal3 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal4 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal5 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal6 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal7 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal8 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal9 {background: url('~@/assets/img/medal.png') no-repeat;background-size: 34px;}
.medal {width: 34px;height: 27px;position: relative;display: inline-block;vertical-align: middle;}
.medal .level {position: absolute;top: 1px;left: 0;color: #000;font-size: 12px;width: 34px;text-align: center;line-height: 1.5em;}
.leveltxt {margin: 10px 0;font-size: 12px;}
.boardrd pre {white-space: pre-wrap;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
